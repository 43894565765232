import React, { useRef, useEffect, useState } from 'react'
import './view.css'
import TrainStatusComponent from './ClassicCenteredTrainStatusComponent/ClassicCenteredTrainStatusComponent.js'
import axios from 'axios'
import {v4 as uuidV4} from 'uuid'
import ClassicCenteredTrainStatusComponent from './ClassicCenteredTrainStatusComponent/ClassicCenteredTrainStatusComponent.js'
import ClassicLeftBoundTrainStatusComponent from './ClassicLeftBoundTrainStatusComponent/ClassicLeftBoundTrainStatusComponent.js'
import { themes } from '../enums/enums.js'

export default function View({listOfStationsToDisplay, selectedTheme}) {
  const [trainDataList, setTrainDataList] = useState(null)
  class TrainData {
    constructor(stationId = "", stationName = "", color = "", trainColorDisplay = "", trainColorHexValue = "", directionOneDestinationName = "", directionOneTimeOne = "", directionOneTimeTwo = "", directionOneTimeThree = "",
    directionFiveDestinationName = "", directionFiveTimeOne = "", directionFiveTimeTwo = "", directionFiveTimeThree = "") {
      this.stationId = stationId;
      this.color = color;
      this.stationName = stationName
      this.trainColorDisplay = trainColorDisplay
      this.trainColorHexValue = trainColorHexValue
      this.directionOneDestinationName = directionOneDestinationName
      this.directionOneTimeOne = directionOneTimeOne
      this.directionOneTimeTwo = directionOneTimeTwo
      this.directionOneTimeThree = directionOneTimeThree
      this.directionFiveDestinationName = directionFiveDestinationName
      this.directionFiveTimeOne = directionFiveTimeOne
      this.directionFiveTimeTwo = directionFiveTimeTwo
      this.directionFiveTimeThree = directionFiveTimeThree
    }
  }
  

  useEffect(() => {
    async function setTrainData() {
      let trainInformationResponse;
      try {
        if(listOfStationsToDisplay.length != 0 && listOfStationsToDisplay){
          trainInformationResponse = await axios.post('https://www.choocago.com/api/trains', listOfStationsToDisplay);
        }
        if (!trainInformationResponse || !trainInformationResponse.data || !trainInformationResponse.data.trainBanners) {
          console.error('No data or trainBanners field in response');
          setTrainDataList([]);
          return;
        }
      } catch (error) {
        console.error('Failed to fetch train information:', error);
        return;
      }
  
      const trainBannersListResponse = trainInformationResponse.data.trainBanners;
      let tempTrainDataList = [];
      let idCounter = 1;
  
      trainBannersListResponse.forEach(singleTrainBanner => {
        const trainData = new TrainData(
          singleTrainBanner.stationId,
          singleTrainBanner.stationName,
          singleTrainBanner.trainColor,
          singleTrainBanner.trainColorDisplay,
          singleTrainBanner.trainColorHexValue
        );
  
        trainData.directionOneDestinationName = singleTrainBanner.trainDirectionOneInformation.destinationName;
        trainData.directionFiveDestinationName = singleTrainBanner.trainDirectionFiveInformation.destinationName;
  
        for (let i = 0; i < 3; i++) {
          updateDirectionTimes(singleTrainBanner, trainData, i);
        }
  
        switch(selectedTheme){
          case themes.ClassicLeftBound:
            tempTrainDataList.push(
              <ClassicLeftBoundTrainStatusComponent
                trainData={trainData}
                bannerId={idCounter}
                key={uuidV4()}
              />
            );
            break;
          case themes.ClassicCentered:
            tempTrainDataList.push(
              <ClassicCenteredTrainStatusComponent
                trainData={trainData}
                bannerId={idCounter}
                key={uuidV4()}
              />
            );
            break;
          default:
            tempTrainDataList.push(
              <ClassicLeftBoundTrainStatusComponent
                trainData={trainData}
                bannerId={idCounter}
                key={uuidV4()}
              />
            );
            break;
        }
        idCounter++;
      });
  
      setTrainDataList(tempTrainDataList);
    }
  
    if (listOfStationsToDisplay.length > 0) {
      setTrainData();
      const interval = setInterval(setTrainData, 20000);
      return () => clearInterval(interval);
    }
  }, [listOfStationsToDisplay, selectedTheme]);

  function updateDirectionTimes(singleTrainBanner, trainData, i) {
      const directionOneTrainArrivalDetails = singleTrainBanner.trainDirectionOneInformation.trainArrivalDetails;
      const directionFiveTrainArrivalDetails = singleTrainBanner.trainDirectionFiveInformation.trainArrivalDetails;

      updateDirectionOneTimes(directionOneTrainArrivalDetails, trainData, i);
      updateDirectionFiveTimes(directionFiveTrainArrivalDetails, trainData, i);
  }

  function updateDirectionOneTimes(arrivalDetails, trainData, index) {
      let arrivalTime = '-';
      if (arrivalDetails[index]) {
          arrivalTime = arrivalDetails[index].arrivalTime;
          switch (index) {
              case 0:
                  trainData.directionOneTimeOne = arrivalDetails[index].isApproaching == '1' ? 'Due' : arrivalTime;
                  break;
              case 1:
                  trainData.directionOneTimeTwo = arrivalTime;
                  break;
              case 2:
                  trainData.directionOneTimeThree = arrivalTime;
                  break;
              default:
                  break;
          }
      }
  }

  function updateDirectionFiveTimes(arrivalDetails, trainData, index) {
      let arrivalTime = '-';
      if (arrivalDetails[index]) {
          arrivalTime = arrivalDetails[index].arrivalTime;
          switch (index) {
              case 0:
                  trainData.directionFiveTimeOne = arrivalDetails[index].isApproaching == '1' ? 'Due' : arrivalTime;
                  break;
              case 1:
                  trainData.directionFiveTimeTwo = arrivalTime;
                  break;
              case 2:
                  trainData.directionFiveTimeThree = arrivalTime;
                  break;
              default:
                  break;
          }
      }
  }


  return (
    <>
      {trainDataList ? (
        <div className='view-container'>
          {trainDataList}
        </div>
      ) : (
        <div>Loading train data...</div>
      )}
    </>
    
  )
}
