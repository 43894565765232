export const listOfRedLineStations = [
    {
      stationName: 'Howard',
      stationId: '40900'
    },
    {
      stationName: 'Jarvis',
      stationId: '41190'
    },
    {
      stationName: 'Morse',
      stationId: '40100'
    },
    {
      stationName: 'Loyola',
      stationId: '41300'
    },
    {
      stationName: 'Granville',
      stationId: '40760'
    },
    {
      stationName: 'Thorndale',
      stationId: '40880'
    },
    {
      stationName: 'Bryn Mawr',
      stationId: '41380'
    },
    {
      stationName: 'Berwyn',
      stationId: '40340'
    },
    {
      stationName: 'Argyle',
      stationId: '41200'
    },
    {
      stationName: 'Lawrence',
      stationId: '40770'
    },
    {
      stationName: 'Wilson',
      stationId: '40540'
    },
    {
      stationName: 'Sheridan',
      stationId: '40080'
    },
    {
      stationName: 'Addison',
      stationId: '41420'
    },
    {
      stationName: 'Belmont',
      stationId: '41320'
    },
    {
      stationName: 'Fullerton',
      stationId: '41220'
    },
    {
      stationName: 'North/Clybourn',
      stationId: '40650'
    },
    {
      stationName: 'Clark/Division',
      stationId: '40630'
    },
    {
      stationName: 'Chicago',
      stationId: '41450'
    },
    {
      stationName: 'Grand',
      stationId: '40330'
    },
    {
      stationName: 'Lake',
      stationId: '41660'
    },
    {
      stationName: 'Monroe',
      stationId: '41090'
    },
    {
      stationName: 'Jackson',
      stationId: '40560'
    },
    {
      stationName: 'Harrison',
      stationId: '41490'
    },
    {
      stationName: 'Roosevelt',
      stationId: '41400'
    },
    {
      stationName: 'Cermak-Chinatown',
      stationId: '41000'
    },
    {
      stationName: 'Sox-35th',
      stationId: '40190'
    },
    {
      stationName: '47th',
      stationId: '41230'
    },
    {
      stationName: 'Garfield',
      stationId: '41170'
    },
    {
      stationName: '63rd',
      stationId: '40910'
    },
    {
      stationName: '69th',
      stationId: '40990'
    },
    {
      stationName: '79th',
      stationId: '40240'
    },
    {
      stationName: '87th',
      stationId: '41430'
    },
    {
      stationName: '95th/Dan Ryan',
      stationId: '40450'
    }
];