export const listOfGreenLineStations = [
  {
      stationName: 'Harlem', // Harlem/Lake ?
      stationId: '40020'
  },
  {
      stationName: 'Oak Park',
      stationId: '41350'
  },
  {
      stationName: 'Ridgeland',
      stationId: '40610'
  },
  {
      stationName: 'Austin',
      stationId: '41260'
  },
  {
      stationName: 'Central',
      stationId: '40280'
  },
  {
      stationName: 'Laramie',
      stationId: '40700'
  },
  {
      stationName: 'Cicero',
      stationId: '40480'
  },
  {
      stationName: 'Pulaski',
      stationId: '40030'
  },
  {
      stationName: 'Conservatory', // Conservatory/-Central Park Drive ?
      stationId: '41670'
  },
  {
      stationName: 'Kedzie',
      stationId: '41070'
  },
  {
      stationName: 'California',
      stationId: '41360'
  },
  {
      stationName: 'Ashland',
      stationId: '40170'
  },
  {
      stationName: 'Morgan',
      stationId: '41510'
  },
  {
      stationName: 'Clinton',
      stationId: '41160'
  },
  {
      stationName: 'Clark/Lake',
      stationId: '40380'
  },
  {
      stationName: 'State/Lake',
      stationId: '40260'
  },
  {
      stationName: 'Washington/Wabash',
      stationId: '41700'
  },
  {
      stationName: 'Adams/Wabash',
      stationId: '40680'
  },
  {
      stationName: 'Roosevelt',
      stationId: '41400'
  },
  {
      stationName: 'Cermak-McCormick Place',
      stationId: '41690'
  },
  {
      stationName: '35th-Bronzeville-IIT',
      stationId: '41120'
  },
  {
      stationName: 'Indiana',
      stationId: '40300'
  },
  {
      stationName: '43rd',
      stationId: '41270'
  },
  {
      stationName: '47th',
      stationId: '41080'
  },
  {
      stationName: '51st',
      stationId: '40130'
  },
  {
      stationName: 'Garfield',
      stationId: '40510'
  },
  {
      stationName: 'King Drive',
      stationId: '41140'
  },
  {
      stationName: 'Halsted',
      stationId: '40940'
  },
  {
      stationName: 'Ashland/63rd',
      stationId: '40290'
  },
  {
      stationName: 'Cottage Grove',
      stationId: '40720'
  }
];
