export const listOfBrownLineStations = [
    {
      stationName: 'Kimball',
      stationId: '41290'
    },
    {
      stationName: 'Kedzie',
      stationId: '41180'
    },
    {
      stationName: 'Francisco',
      stationId: '40870'
    },
    {
      stationName: 'Rockwell',
      stationId: '41010'
    },
    {
      stationName: 'Western',
      stationId: '41480'
    },
    {
      stationName: 'Damen',
      stationId: '40090'
    },
    {
      stationName: 'Montrose',
      stationId: '41500'
    },
    {
      stationName: 'Irving Park',
      stationId: '41460'
    },
    {
      stationName: 'Addison',
      stationId: '41440'
    },
    {
      stationName: 'Paulina',
      stationId: '41310'
    },
    {
      stationName: 'Southport',
      stationId: '40360'
    },
    {
      stationName: 'Belmont',
      stationId: '41320'
    },
    {
      stationName: 'Wellington',
      stationId: '41210'
    },
    {
      stationName: 'Diversey',
      stationId: '40530'
    },
    {
      stationName: 'Fullerton',
      stationId: '41220'
    },
    {
      stationName: 'Armitage',
      stationId: '40660'
    },
    {
      stationName: 'Sedgwick',
      stationId: '40800'
    },
    {
      stationName: 'Chicago',
      stationId: '40710'
    },
    {
      stationName: 'Merchandise Mart',
      stationId: '40460'
    },
    {
      stationName: 'Washington/Wells',
      stationId: '40730'
    },
    {
      stationName: 'Quincy',
      stationId: '40040'
    },
    {
      stationName: 'LaSalle/Van Buren',
      stationId: '40160'
    },
    {
      stationName: 'Harold Washington Library',
      stationId: '40850'
    },
    {
      stationName: 'Adams/Wabash',
      stationId: '40680'
    },
    {
      stationName: 'State/Lake',
      stationId: '40260'
    },
    {
      stationName: 'Clark/Lake',
      stationId: '40380'
    }
];
