import React, { useRef, useEffect, useState } from 'react'
import './customization-screen-one.css'
import axios from 'axios'
import trainLogo from '../images/train_logo.png'
import busLogo from '../images/bus_logo.png'
import addIcon from '../images/add_icon.png'
import removeIcon from '../images/remove_icon.png'
import cogIcon from '../images/cog_icon.png'
import contactIcon from '../images/contact_icon.png'
import themeIcon from '../images/theme_icon.png'

export default function CustomizationScreenOne({ clickedAddTrainBus, clickedRemoveTrainOrBus, clickedTheme }) {
  
  return (
    <div className='screen-one-container'>
      <div className='screen-one-options-list-container'>
        <div className='screen-one-single-option-container' onClick={clickedAddTrainBus}>
          <img src={trainLogo} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Add train to list
          </div>
        </div>
        <div className='screen-one-single-option-container' onClick={clickedAddTrainBus}>
          <img src={busLogo} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Add bus to list
          </div>
        </div>
        <div className='screen-one-single-option-container' onClick={clickedRemoveTrainOrBus}>
          <img src={removeIcon} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Remove train or bus from list
          </div>
        </div>
        <div className='screen-one-single-option-container'>
          <img src={removeIcon} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Edit list order
          </div>
        </div>
        <div className='screen-one-divider-container'>
            <div className='screen-one-divider-center-line'></div>
        </div>
        <div className='screen-one-single-option-container' onClick={clickedTheme}>
          <img src={themeIcon} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Theme
          </div>
        </div>
        <div className='screen-one-single-option-container'>
          <img src={cogIcon} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Settings
          </div>
        </div>
        <div className='screen-one-single-option-container'>
          <img src={contactIcon} className='screen-one-single-option-icon' />
          <div className='screen-one-single-option-text'>
            Contact
          </div>
        </div>
      </div>
    </div>
  )
}
