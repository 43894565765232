import React, { useRef, useEffect, useState } from 'react'
import './customization-select-station.css'
import triangleSortUp from '../../images/triangle_sort_up.png'
import IndividualStationSelection from '../Miscellaneous/IndividualStationSelection'
import { listOfRedLineStations } from '../../enums/ListOfStations/RedLineStations'
import { listOfBlueLineStations } from '../../enums/ListOfStations/BlueLineStations'
import { listOfBrownLineStations } from '../../enums/ListOfStations/BrownLineStations'
import { listOfPurpleLineStations } from '../../enums/ListOfStations/PurpleLineStations'
import { listOfGreenLineStations } from '../../enums/ListOfStations/GreenLineStations'
import { listOfPinkLineStations } from '../../enums/ListOfStations/PinkLineStations'
import { listOfYellowLineStations } from '../../enums/ListOfStations/YellowLineStations'
import { listOfOrangeLineStations } from '../../enums/ListOfStations/OrangeLineStations'

import {v4 as uuidV4} from 'uuid'

export default function CustomizationSelectStation({addTrainStationToList, lineColor}) {
  let listOfStations = ['Error']
  let lineColorHex = '#ffffff'
  switch (lineColor) {
    case "red":
      listOfStations = listOfRedLineStations
      lineColorHex = '#c60c30'
      break
    case "blue":
      listOfStations = listOfBlueLineStations
      lineColorHex = '#00a1de'
      break
    case "brn":
      listOfStations = listOfBrownLineStations
      lineColorHex = '#62361b'
      break
    case "p":
      listOfStations = listOfPurpleLineStations
      lineColorHex = '#522398'
      break
    case "g":
      listOfStations = listOfGreenLineStations
      lineColorHex = '#009b3a'
      break
    case "y":
      listOfStations = listOfYellowLineStations
      lineColorHex = '#f9e300'
      break
    case "pink":
      listOfStations = listOfPinkLineStations
      lineColorHex = '#e27ea6'
      break
    case "org":
      listOfStations = listOfOrangeLineStations
      lineColorHex = '#f9461c'
      break
  }

  return (
    <div className='customization-select-station-container'>
      <div className='customization-select-station-title-container'>
        Select station
      </div>
      <div className='customization-select-station-search-and-sort-container'>
        <input placeholder='Station name' className='customization-select-station-search-container' />
        <div className='customization-select-station-sort-container'>
          <div className='customization-select-station-sort-text'>
            Sort
          </div>
          <img src={triangleSortUp} className='customization-select-station-sort-icon'/>
        </div>
      </div>
      <div className='customization-select-station-all-stations-scroll-container'>
        <div className='temp-line'></div>
        <div className='customization-select-station-all-stations-container'>
          {listOfStations.map(station => <IndividualStationSelection 
            addTrainStationToList={addTrainStationToList} 
            stationName={station.stationName} 
            stationId={station.stationId} 
            lineColor={lineColor} 
            lineColorHex={lineColorHex}
            key={uuidV4()}/>
          )} 
        </div>
      </div>
    </div>
  )
}
