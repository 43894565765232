import React, { useEffect, useState } from 'react';
import RemoveStationIndividualSelection from './RemoveStationIndividualSelection'; // Ensure this path is correct
import './customization-remove-station.css';

export default function CustomizationRemoveStation({ listOfStationsToDisplay, removeStationClick }) {
  const [trainStationsList, setTrainStationsList] = useState([]);

  useEffect(() => {
    const localStorageTrainStationsList = localStorage.getItem('TrainStationsList');
    if (localStorageTrainStationsList) {
      const stationStrings = localStorageTrainStationsList.split('&');
      const trainStationsArray = stationStrings.map(stationString => {
        const cleanedString = stationString.slice(1, -1);
        const [stationId, stationName, color, colorHex] = cleanedString.split(',');
        return {
          stationId: stationId,
          stationName: stationName,
          color: color,
          colorHex: colorHex
        };
      });

      setTrainStationsList(trainStationsArray);
    }
  }, []);

  function removeStationFromRemoveList(stationId, color) {
    // // Remove from localStorage
    // let localStorageTrainStationsList = localStorage.getItem('TrainStationsList');
    // if (localStorageTrainStationsList) {
    //   const stationStrings = localStorageTrainStationsList.split('&');
    //   const updatedStationsArray = stationStrings.filter(stationString => {
    //     const cleanedString = stationString.slice(1, -1);
    //     const [id] = cleanedString.split(',');
    //     return id !== stationId;
    //   });
    //   localStorageTrainStationsList = updatedStationsArray.join('&');
    //   localStorage.setItem('TrainStationsList', localStorageTrainStationsList);
    // }

    // Remove from state
    setTrainStationsList(prevState => prevState.filter(station => station.stationId !== stationId || station.color !== color));
  }

  return (
    <div className='customization-remove-station-container'>
      <div className='customization-remove-station-title-container'>Select station to remove</div>
      <div className='customization-remove-station-list-container'>
        {listOfStationsToDisplay.length === 0 ? (
          <p>No stations available</p>
        ) : (
          listOfStationsToDisplay.map((station, index) => (
            <RemoveStationIndividualSelection
              key={index}
              stationId={station.stationId}
              stationName={station.stationName}
              color={station.color}
              colorHex={station.colorHex}
              removeStationFunctionCalls={() => {
                removeStationClick(station.stationId, station.color);
                removeStationFromRemoveList(station.stationId, station.color);
              }}
            />
          ))
        )}
      </div>
    </div>
  );
}
