import React, { useRef, useEffect, useState } from 'react'
import './styles/home-page.css'
import View from './View/View.js'
import Customization from './Customization/Customization.js'
import Navbar from './Navbar/Navbar.js'
import { themes } from './enums/enums.js'

export default function HomePage() {
  const [listOfStationsToDisplayState, setListOfStationsToDisplayState] = useState([])
  const [customizationVisible, setCustomizationVisible] = useState(false);
  const [selectedTheme, setselectedTheme] = useState(null)

  useEffect(() => {
    const localStorageTheme = localStorage.getItem('Theme')
    if(localStorageTheme == null || localStorageTheme == undefined) {
      localStorage.setItem('Theme', themes.ClassicLeftBound)
    }
    else{
      setselectedTheme(localStorageTheme)
    }

    const localStorageTrainStationsList = localStorage.getItem('TrainStationsList');
    if(localStorageTrainStationsList && localStorageTrainStationsList.charAt(0) != '['){
      localStorage.setItem('TrainStationsList', '')
    }
    if (localStorageTrainStationsList) {
      const stationStrings = localStorageTrainStationsList.split('&');
      const trainStationsListArray = stationStrings.map(stationString => {
        const cleanedString = stationString.slice(1, -1);
        const [stationId, stationName, color, colorHex] = cleanedString.split(',');
        return {
          stationId: stationId,
          stationName: stationName,
          color: color,
          colorHex: colorHex
        };
      });

      setListOfStationsToDisplayState(trainStationsListArray);
    }
  }, []);

  function closeCustomizationModal(e) {
    if(e.target.id == 'customization-background' || e.target.id == 'customization-close-button'){
      setCustomizationVisible(false);
      document.body.style.position = '';
    }
  }

  function openCustomizationModal() {
    setCustomizationVisible(true);
    document.body.style.position = 'fixed';
  }

  function addTrainStationToList(stationName, stationId, color, colorHex) {
    let localStorageTrainStationsList = localStorage.getItem('TrainStationsList');
    let trainStationsListArray = [];
  
    if (localStorageTrainStationsList) {
      const stationStrings = localStorageTrainStationsList.split('&');
      trainStationsListArray = stationStrings.map(stationString => {
        const cleanedString = stationString.slice(1, -1);
        const [id, name, stationColor, hex] = cleanedString.split(',');
        return {
          stationId: id,
          stationName: name,
          color: stationColor,
          colorHex: hex
        };
      });
  
      // Check if the station is already in the list
      const stationExists = trainStationsListArray.some(station => station.stationId === stationId && station.color === color);
      if (stationExists) {
        return; // Station already is in, so we do nothing
      }
    }
  
    // Add the new station to the array
    trainStationsListArray.push({ stationId, stationName, color, colorHex });
  
    // Convert the array back to the string format for localStorage
    localStorageTrainStationsList = trainStationsListArray
      .map(station => `[${station.stationId},${station.stationName},${station.color},${station.colorHex}]`)
      .join('&');
  
    // Update localStorage
    localStorage.setItem('TrainStationsList', localStorageTrainStationsList);
  
    // Update state
    setListOfStationsToDisplayState(prevState => [
      ...prevState,
      { stationId, stationName, color: color, colorHex: colorHex }
    ]);
  }

  function removeStationClick(stationId, color) {
    let localStorageTrainStationsList = localStorage.getItem('TrainStationsList');
    if (!localStorageTrainStationsList) {
      return; // Nothing to remove if the list is empty
    }
  
    // Parse the list from localStorage
    const stationStrings = localStorageTrainStationsList.split('&');
    let trainStationsListArray = stationStrings.map(stationString => {
      const cleanedString = stationString.slice(1, -1); // Remove the brackets
      const [id, name, color, hex] = cleanedString.split(',');
      return {
        stationId: id,
        stationName: name,
        color: color,
        colorHex: hex
      };
    });
  
    // Filter out the station to remove
    console.log(color, trainStationsListArray[1].color)
    trainStationsListArray = trainStationsListArray.filter(station => (station.stationId !== stationId || station.color !== color));
  
    // Convert the array back to the string format for localStorage
    localStorageTrainStationsList = trainStationsListArray
      .map(station => `[${station.stationId},${station.stationName},${station.color},${station.colorHex}]`)
      .join('&');
  
    // Update localStorage
    localStorage.setItem('TrainStationsList', localStorageTrainStationsList);
  
    // Optionally update state if you are maintaining the list in a state variable
    setListOfStationsToDisplayState(trainStationsListArray);
  }

  function clickedThemeChoice(selectedTheme) {
    localStorage.setItem('Theme', selectedTheme)
    setselectedTheme(selectedTheme)
  }

  return (
    <div className='home-page-container'>
      <Navbar openCustomizationModal={openCustomizationModal}/>
      <View listOfStationsToDisplay={listOfStationsToDisplayState} selectedTheme={selectedTheme}/>
      {customizationVisible && (
        <Customization
          listOfStationsToDisplay={listOfStationsToDisplayState}
          closeCustomizationModal={closeCustomizationModal}
          addTrainStationToList={addTrainStationToList}
          removeStationClick={removeStationClick}
          clickedThemeChoice={clickedThemeChoice}
        />
      )}
    </div>
  )
}
