export const listOfPinkLineStations = [
  {
      stationName: '54th/Cermak',
      stationId: '40580'
  },
  {
      stationName: 'Cicero',
      stationId: '40420'
  },
  {
      stationName: 'Kostner',
      stationId: '40600'
  },
  {
      stationName: 'Pulaski',
      stationId: '40150'
  },
  {
      stationName: 'Central Park',
      stationId: '40780'
  },
  {
      stationName: 'Kedzie',
      stationId: '41040'
  },
  {
      stationName: 'California',
      stationId: '40440'
  },
  {
      stationName: 'Western',
      stationId: '40740'
  },
  {
      stationName: 'Damen',
      stationId: '40210'
  },
  {
      stationName: '18th',
      stationId: '40830'
  },
  {
      stationName: 'Polk',
      stationId: '41030'
  },
  {
      stationName: 'Ashland',
      stationId: '40170'
  },
  {
      stationName: 'Morgan',
      stationId: '41510'
  },
  {
      stationName: 'Clinton',
      stationId: '41160'
  },
  {
      stationName: 'Clark/Lake',
      stationId: '40380'
  }
];
