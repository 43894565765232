export const listOfOrangeLineStations = [
    {
        stationName: 'Midway',
        stationId: '40930'
    },
    {
        stationName: 'Pulaski',
        stationId: '40960'
    },
    {
        stationName: 'Kedzie',
        stationId: '41150'
    },
    {
        stationName: 'Western',
        stationId: '40310'
    },
    {
        stationName: '35th/Archer',
        stationId: '40120'
    },
    {
        stationName: 'Ashland',
        stationId: '41060'
    },
    {
        stationName: 'Halsted',
        stationId: '41130'
    },
    {
        stationName: 'Roosevelt/Wabash',
        stationId: '41400'
    },
    {
        stationName: 'Harold Washington Library-State/Van Buren', // LONGEST!
        stationId: '40850'
    },
    {
        stationName: 'LaSalle/Van Buren',
        stationId: '40160'
    },
    {
        stationName: 'Quincy/Wells',
        stationId: '40040'
    },
    {
        stationName: 'Washington/Wells',
        stationId: '40730'
    },
    {
        stationName: 'Clark/Lake',
        stationId: '40380'
    }
];
