import React, { useRef, useEffect, useState } from 'react'
import './individual-station-selection.css'

export default function IndividualStationSelection({addTrainStationToList, stationName, stationId, lineColor, lineColorHex}) {

  function clickNewStation() {
    addTrainStationToList(stationName, stationId, lineColor, lineColorHex)
  }

  return (
    <div className='individual-station-selection-container'>
      <div className='individual-station-selection-click-container' onClick={clickNewStation}>
        <div className='individual-station-selection-station-art-container'>
          <div style={{backgroundColor: lineColorHex}} className='individual-station-selection-station-art-rounded-box'></div>
          <div style={{backgroundColor: lineColorHex}} className='individual-station-selection-station-art-line'></div>
        </div>
        <div className='individual-station-selection-station-name'>
          {stationName}
        </div>
      </div>
      
    </div>
  )
}
