export const listOfYellowLineStations = [
  {
      stationName: 'Dempster-Skokie',
      stationId: '40140'
  },
  {
      stationName: 'Oakton-Skokie',
      stationId: '41680'
  },
  {
      stationName: 'Howard',
      stationId: '40900'
  }
];