import React, { useRef, useEffect, useState } from 'react'
import './customization-select-line-color.css'
import liveTrainTrackerImage from '../../images/live_train_estimate_logo_white.png'

export default function CustomizationSelectLineColor({trainColorClicked}) {

  function trainColorClickedLocal(color){
    trainColorClicked(color)
  }

  return (
    <div className='customization-add-train-container'>
      <div className='customization-add-train-title-container'>
        Select a line color
      </div>
      <div className='customization-add-train-train-colors-list-container'>
        <div className='customization-add-train-train-colors-list-row-pair-container'>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('red')}>
            <div style={{backgroundColor: '#c60c30'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Red line
            </div>
          </div>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('blue')}>
            <div style={{backgroundColor: '#00a1de'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Blue line
            </div>
          </div>
        </div>
        <div className='customization-add-train-train-colors-list-row-pair-container'>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('brn')}>
            <div style={{backgroundColor: '#62361b'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Brown line
            </div>
          </div>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('p')}>
            <div style={{backgroundColor: '#522398'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Purple line
            </div>
          </div>
        </div>
        <div className='customization-add-train-train-colors-list-row-pair-container'>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('pink')}>
            <div style={{backgroundColor: '#e27ea6'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Pink line
            </div>
          </div>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('g')}>
            <div style={{backgroundColor: '#009b3a'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Green line
            </div>
          </div>
        </div>
        <div className='customization-add-train-train-colors-list-row-pair-container'>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('org')}>
            <div style={{backgroundColor: '#f9461c'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Orange line
            </div>
          </div>
          <div className='customization-add-train-train-colors-list-individual-cell' onClick={() => trainColorClickedLocal('y')}>
            <div style={{backgroundColor: '#f9e300'}} className='customization-add-train-train-colors-list-bullet-point'></div>
            <div className='customization-add-train-train-colors-list-text'>
              Yellow line
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
