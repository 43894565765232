export const listOfBlueLineStations = [
    {
      stationName: 'O\'Hare',
      stationId: '40890'
    },
    {
      stationName: 'Rosemont',
      stationId: '40820'
    },
    {
      stationName: 'Cumberland',
      stationId: '40230'
    },
    {
      stationName: 'Harlem',
      stationId: '40750'
    },
    {
      stationName: 'Jefferson Park',
      stationId: '41280'
    },
    {
      stationName: 'Montrose',
      stationId: '41330'
    },
    {
      stationName: 'Irving Park',
      stationId: '40550'
    },
    {
      stationName: 'Addison',
      stationId: '41240'
    },
    {
      stationName: 'Belmont',
      stationId: '40060'
    },
    {
      stationName: 'Logan Square',
      stationId: '41020'
    },
    {
      stationName: 'California',
      stationId: '40570'
    },
    {
      stationName: 'Western',
      stationId: '40670'
    },
    {
      stationName: 'Damen',
      stationId: '40590'
    },
    {
      stationName: 'Division',
      stationId: '40320'
    },
    {
      stationName: 'Chicago',
      stationId: '41410'
    },
    {
      stationName: 'Grand',
      stationId: '40490'
    },
    {
      stationName: 'Clark/Lake',
      stationId: '40380'
    },
    {
      stationName: 'Washington',
      stationId: '40370'
    },
    {
      stationName: 'Monroe',
      stationId: '40790'
    },
    {
      stationName: 'Jackson',
      stationId: '40070'
    },
    {
      stationName: 'LaSalle',
      stationId: '41340'
    },
    {
      stationName: 'Clinton',
      stationId: '40430'
    },
    {
      stationName: 'UIC-Halsted',
      stationId: '40350'
    },
    {
      stationName: 'Racine',
      stationId: '40470'
    },
    {
      stationName: 'Illinois Medical District',
      stationId: '40810'
    },
    {
      stationName: 'Western',
      stationId: '40220'
    },
    {
      stationName: 'Kedzie-Homan',
      stationId: '40250'
    },
    {
      stationName: 'Pulaski',
      stationId: '40920'
    },
    {
      stationName: 'Cicero',
      stationId: '40970'
    },
    {
      stationName: 'Austin',
      stationId: '40010'
    },
    {
      stationName: 'Oak Park',
      stationId: '40180'
    },
    {
      stationName: 'Harlem',
      stationId: '40980'
    },
    {
      stationName: 'Forest Park',
      stationId: '40390'
    }
];
