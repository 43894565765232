import React, { useRef, useEffect, useState } from 'react'
import './customization-theme.css'
import { themes } from '../../enums/enums.js'

export default function CustomizationTheme({clickedThemeChoice}) {
  
  return (
    <div className='select-theme-container'>
        <div className='select-theme-title-container'>Select theme</div>
        <div className='theme-choice-container' onClick={() => clickedThemeChoice(themes.ClassicLeftBound)}>
          Classic - Left Bound
        </div>
        <div className='theme-choice-container' onClick={() => clickedThemeChoice(themes.ClassicCentered)}>
          Classic - Centered
        </div>
        {/* <div className='theme-choice-container'>
          Condensed
        </div> */}
    </div>
  )
}
