import React, { useState, useEffect } from 'react';
import './customization.css';
import CustomizationScreenOne from './CustomizationScreenOne';
import CustomizationSelectLineColor from './AddStationsFiles/CustomizationSelectLineColor';
import CustomizationSelectStation from './AddStationsFiles/CustomizationSelectStation';
import CustomizationRemoveStation from './RemoveStationFiles/CustomizationRemoveStation';
import CustomizationTheme from './CustomizationTheme/CustomizationTheme'

export default function Customization({ listOfStationsToDisplay, closeCustomizationModal, addTrainStationToList, removeStationClick, clickedThemeChoice }) {
  const [customizationScreen, setCustomizationScreen] = useState(null);
  const [headerAndBackButton, setHeaderAndBackButton] = useState('Customize');
  const [closeButton, setCloseButton] = useState('X');

  useEffect(() => {
    setCustomizationScreen(
      <CustomizationScreenOne 
        clickedAddTrainBus={clickedAddTrainBus} 
        clickedRemoveTrainOrBus={clickedRemoveTrainOrBus}
        clickedTheme={clickedTheme}
      />
    );
    setHeaderAndBackButton('Customize');
    setCloseButton('X');
  }, []);

  function clickedAddTrainBus() {
    setCustomizationScreen(<CustomizationSelectLineColor trainColorClicked={trainColorClicked} />);
    setHeaderAndBackButton('Back');
  }

  function clickedRemoveTrainOrBus() {
    setCustomizationScreen(<CustomizationRemoveStation listOfStationsToDisplay={listOfStationsToDisplay} removeStationClick={removeStationClick}/>);
    setHeaderAndBackButton('Back');
  }

  function clickedTheme() {
    setCustomizationScreen(<CustomizationTheme clickedThemeChoice={clickedThemeChoice}/>)
    setHeaderAndBackButton('Back')
  }

  function trainColorClicked(lineColor) {
    setCustomizationScreen(
      <CustomizationSelectStation addTrainStationToList={addTrainStationToList} lineColor={lineColor} />
    );
  }

  function headerAndBackButtonClicked() {
    if(headerAndBackButton == 'Back'){
      setCustomizationScreen(
        <CustomizationScreenOne 
          clickedAddTrainBus={clickedAddTrainBus} 
          clickedRemoveTrainOrBus={clickedRemoveTrainOrBus}
          clickedTheme={clickedTheme}
        />
      );
      setHeaderAndBackButton('Customize');
    }
  }

  return (
    <div className='customization-background' id='customization-background' onClick={closeCustomizationModal}>
      <div className='customization-container' id='customization-container'>
        <div className='customization-header-container'>
          <div className='customization-header-title-and-back-button' onClick={headerAndBackButtonClicked}>{headerAndBackButton}</div>
          <div className='customization-close-button' id='customization-close-button' onClick={closeCustomizationModal}>
            {closeButton}
          </div>
        </div>
        <div className='customization-body-container'>
          {customizationScreen}
        </div>
      </div>
    </div>
  );
}
