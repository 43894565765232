import React, { useEffect, useState } from 'react';
import './remove-station-individual-selection.css';
import xImage from '../../images/x.png'

export default function RemoveStationIndividualSelection({ stationId, stationName, color, colorHex, removeStationFunctionCalls }) {

  return (
    <div className='remove-station-individual-selection-container' onClick={() => removeStationFunctionCalls()}>
        <div className='remove-station-x-container'>
          <img src={xImage} className='x-image'/>
        </div>
        <div className='remove-station-banner-container' style={{backgroundColor: colorHex}}>
          {stationName}
        </div>
    </div>
  );
}
