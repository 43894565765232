import React, { useRef, useEffect, useState } from 'react';
import './navbar.css';

export default function Navbar({ openCustomizationModal }) {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    // Function to update the time
    const updateClock = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes} ${hours >= 12 ? 'pm' : 'am'}`;
      setCurrentTime(formattedTime);
    };

    // Update the clock immediately on mount
    updateClock();

    // Set up an interval to update the clock every second
    const intervalId = setInterval(updateClock, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='navbar-container'>
      <div className='navbar-title-time-container'>
        {currentTime}
      </div>
      <div className='navbar-title-container'>
        Choocago!
      </div>
      <div className='navbar-open-customization-container' onClick={openCustomizationModal}>
        Customize
      </div>
    </div>
  );
}
