// verify all the lists. 
//  This list doesnt order the loop correctly

export const listOfPurpleLineStations = [
    {
      stationName: 'Linden',
      stationId: '41050'
    },
    {
      stationName: 'Central',
      stationId: '41250'
    },
    {
      stationName: 'Noyes',
      stationId: '40400'
    },
    {
      stationName: 'Foster',
      stationId: '40520'
    },
    {
      stationName: 'Davis',
      stationId: '40050'
    },
    {
      stationName: 'Dempster',
      stationId: '40690'
    },
    {
      stationName: 'Main',
      stationId: '40270'
    },
    {
      stationName: 'South Boulevard',
      stationId: '40840'
    },
    {
      stationName: 'Howard',
      stationId: '40900'
    },
    {
      stationName: 'Wilson',
      stationId: '40540'
    },
    {
      stationName: 'Belmont',
      stationId: '41320'
    },
    {
      stationName: 'Wellington',
      stationId: '41210'
    },
    {
      stationName: 'Diversey',
      stationId: '40530'
    },
    {
      stationName: 'Fullerton',
      stationId: '41220'
    },
    {
      stationName: 'Armitage',
      stationId: '40660'
    },
    {
      stationName: 'Sedgwick',
      stationId: '40800'
    },
    {
      stationName: 'Chicago',
      stationId: '40710'
    },
    {
      stationName: 'Merchandise Mart',
      stationId: '40460'
    },
    {
      stationName: 'Clark/Lake',
      stationId: '40380'
    },
    {
      stationName: 'State/Lake',
      stationId: '40260'
    },
    {
      stationName: 'Washington/Wells',
      stationId: '40730'
    },
    {
      stationName: 'Quincy',
      stationId: '40040'
    },
    {
      stationName: 'LaSalle/Van Buren',
      stationId: '40160'
    },
    {
      stationName: 'Harold Washington Library',
      stationId: '40850'
    },
    {
      stationName: 'Adams/Wabash',
      stationId: '40680'
    }
];
