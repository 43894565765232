import React, { useRef, useEffect, useState } from 'react'
import './classic-centered-train-status-component.css'
import axios from 'axios'
import liveTrainTrackerImage from '../../images/live_train_estimate_logo_white.png'

export default function ClassicCenteredTrainStatusComponent({trainData, bannerId}) {
  
  return (
    <div className='middle-train-status-component'>
      <div className='middle-train-status-information-container' style={{backgroundColor: trainData.trainColorHexValue}} id={'middle-train-status-information-container-' + bannerId}>
        <div className='mts-destination-and-estimates-container-left'>
          <div className='mts-destination-and-estimates'>
            <div className='mts-destination-name'>
              To {trainData.directionOneDestinationName}
            </div>
            <div className='mts-estimates-container'>
              <div className='mts-estimate'>
                {trainData.directionOneTimeOne}  {trainData.directionOneTimeTwo}
              </div>
            </div>
          </div>
        </div>
        <div className='mts-station-and-line-container'>
          <div className='mts-station-container'>
            {trainData.stationName}
          </div>
          <div className='mts-line-color-container'>
            <img src={liveTrainTrackerImage} className='mts-train-image'></img>
            <div className='mts-line-color-text'>
              {trainData.trainColorDisplay} Line
            </div>
          </div>
        </div>
        <div className='mts-destination-and-estimates-container-right'>
          <div className='mts-destination-and-estimates'>
            <div className='mts-destination-name'>
              To {trainData.directionFiveDestinationName}
            </div>
            <div className='mts-estimates-container'>
              <div className='mts-estimate'>
                {trainData.directionFiveTimeOne} <span style={{width: '15px'}}></span> {trainData.directionFiveTimeTwo}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
