import React, { useRef, useEffect, useState } from 'react'
import './classic-left-bound-train-status-component.css'
import axios from 'axios'
import liveTrainTrackerImage from '../../images/live_train_estimate_logo_white.png'

export default function ClassicLeftBoundTrainStatusComponent({trainData, bannerId}) {
  
  return (
    <div className='left-train-status-component'>
      <div className='left-train-status-information-container' style={{backgroundColor: trainData.trainColorHexValue}} id={'left-train-status-information-container-' + bannerId}>
        <div className='lts-station-and-line-container'>
          <div className='lts-station-container'>
            {trainData.stationName}
          </div>
          <div className='lts-line-color-container'>
            <img src={liveTrainTrackerImage} className='lts-train-image'></img>
            <div className='lts-line-color-text'>
              {trainData.trainColorDisplay} Line
            </div>
          </div>
        </div>

        <div className='lts-destination-and-estimates-container'>
          <div className='lts-destination-and-estimates-container-left'>
            <div className='lts-destination-and-estimates'>
              <div className='lts-destination-name'>
                To {trainData.directionOneDestinationName}
              </div>
              <div className='lts-estimates-container'>
                <div className='lts-estimate'>
                  {trainData.directionOneTimeOne}  {trainData.directionOneTimeTwo}
                </div>
              </div>
            </div>
          </div>
          <div className='lts-destination-and-estimates-container-right'>
            <div className='lts-destination-and-estimates'>
              <div className='lts-destination-name'>
                To {trainData.directionFiveDestinationName}
              </div>
              <div className='lts-estimates-container'>
                <div className='lts-estimate'>
                  {trainData.directionFiveTimeOne} <span style={{width: '15px'}}></span> {trainData.directionFiveTimeTwo}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
